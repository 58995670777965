import { encounterExperienceBudget, challengeRatingInfo } from 'data/encounter'
import { ParsedParticipant } from 'types'

export const css = (...classes: (string | undefined | boolean)[]) =>
  classes.filter((className) => !!className).join(' ')

export function pick<T>(obj: T, ...keys: Array<keyof T>) {
  const newObj: Partial<T> = {}

  keys.forEach((key) => {
    newObj[key] = obj[key]
  })

  return newObj
}

export function omit<T extends {}>(obj: T, ...keys: Array<keyof T>) {
  const copiedObj = { ...obj }
  Object.keys(obj).forEach((key) => {
    if (keys.includes(key as keyof T)) {
      delete copiedObj[key as keyof T]
    }
  })

  return copiedObj
}

export const getEncounterDifficulty = ({
  monsters,
  partyMembers,
}: {
  monsters: ParsedParticipant[]
  partyMembers?: { character?: { level?: number | null } | null }[]
}) => {
  if (!monsters.length) {
    return 'easy'
  }

  const averageLevel = getAveragePartyLevel(partyMembers)

  if (!averageLevel) {
    return undefined
  }

  if (monsters.some((m) => m.monster?.data && !('challengeRating' in m.monster.data))) {
    return 'unknown'
  }

  const totalExperience = getTotalExperience(monsters)

  const difficulty =
    Object.entries(encounterExperienceBudget[averageLevel]).reduce<string | null>(
      (prev, [difficulty, experience]) =>
        experience * (partyMembers?.length || 0) < totalExperience ? difficulty : prev,
      null
    ) || 'easy'

  return difficulty
}

export const getTotalExperience = (monsters: ParsedParticipant[]) =>
  monsters.reduce(
    (prev: number, monster) =>
      prev +
      (challengeRatingInfo.find((cr) => cr.cr === monster?.monster?.data?.challengeRating)?.xp ||
        0),
    0
  )

export const getAveragePartyLevel = (
  partyMembers: { character?: { level?: number | null } | null }[] = []
): number | undefined => {
  return Math.ceil(
    partyMembers.reduce((prev, partyMember) => prev + (partyMember?.character?.level || 0), 0) /
      partyMembers.length
  )
}

export const getAbilityScoreModifier = (score?: number | null) => {
  if (score == undefined) {
    return undefined
  }
  return Math.floor((score - 10) / 2)
}

export const getAbilityBonus = (score?: number) => {
  const bonus = getAbilityScoreModifier(score)

  if (bonus == undefined) {
    return undefined
  }

  return bonus >= 0 ? `+${bonus}` : bonus
}

export const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min

export const formatDateTime = (dateStr: string) => {
  return new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(dateStr ? new Date(dateStr) : new Date())
}

export function isValidUrl(str: string) {
  try {
    new URL(str)
  } catch (_) {
    return false
  }

  return true
}
