export const encounterExperienceBudget = {
  1: { easy: 25, medium: 50, hard: 75, deadly: 100 },
  2: { easy: 50, medium: 100, hard: 150, deadly: 200 },
  3: { easy: 75, medium: 150, hard: 225, deadly: 400 },
  4: { easy: 125, medium: 250, hard: 375, deadly: 500 },
  5: { easy: 250, medium: 500, hard: 750, deadly: 1100 },
  6: { easy: 300, medium: 600, hard: 900, deadly: 1400 },
  7: { easy: 350, medium: 750, hard: 1100, deadly: 1700 },
  8: { easy: 450, medium: 900, hard: 1400, deadly: 2100 },
  9: { easy: 550, medium: 1100, hard: 1600, deadly: 2400 },
  10: { easy: 600, medium: 1200, hard: 1900, deadly: 2800 },
  11: { easy: 800, medium: 1600, hard: 2400, deadly: 3600 },
  12: { easy: 1000, medium: 2000, hard: 3000, deadly: 4500 },
  13: { easy: 1100, medium: 2200, hard: 3400, deadly: 5100 },
  14: { easy: 1250, medium: 2500, hard: 3800, deadly: 5700 },
  15: { easy: 1400, medium: 2800, hard: 4300, deadly: 6400 },
  16: { easy: 1600, medium: 3200, hard: 4800, deadly: 7200 },
  17: { easy: 2000, medium: 3900, hard: 5900, deadly: 8800 },
  18: { easy: 2100, medium: 4200, hard: 6300, deadly: 9500 },
  19: { easy: 2400, medium: 4900, hard: 7300, deadly: 10900 },
  20: { easy: 2800, medium: 5700, hard: 8500, deadly: 12700 },
} as Record<number, Record<string, number>>

export const challengeRatingInfo = [
  { cr: 0, label: '0', xp: 10, proficiency: 2 },
  { cr: 0.125, label: '1/8', xp: 25, proficiency: 2 },
  { cr: 0.25, label: '1/4', xp: 50, proficiency: 2 },
  { cr: 0.5, label: '1/2', xp: 100, proficiency: 2 },
  { cr: 1, label: '1', xp: 200, proficiency: 2 },
  { cr: 2, label: '2', xp: 450, proficiency: 2 },
  { cr: 3, label: '3', xp: 700, proficiency: 2 },
  { cr: 4, label: '4', xp: 1100, proficiency: 2 },
  { cr: 5, label: '5', xp: 1800, proficiency: 3 },
  { cr: 6, label: '6', xp: 2300, proficiency: 3 },
  { cr: 7, label: '7', xp: 2900, proficiency: 3 },
  { cr: 8, label: '8', xp: 3900, proficiency: 3 },
  { cr: 9, label: '9', xp: 5000, proficiency: 4 },
  { cr: 10, label: '10', xp: 5900, proficiency: 4 },
  { cr: 11, label: '11', xp: 7200, proficiency: 4 },
  { cr: 12, label: '12', xp: 8400, proficiency: 4 },
  { cr: 13, label: '13', xp: 10000, proficiency: 5 },
  { cr: 14, label: '14', xp: 11500, proficiency: 5 },
  { cr: 15, label: '15', xp: 13000, proficiency: 5 },
  { cr: 16, label: '16', xp: 15000, proficiency: 5 },
  { cr: 17, label: '17', xp: 18000, proficiency: 6 },
  { cr: 18, label: '18', xp: 20000, proficiency: 6 },
  { cr: 19, label: '19', xp: 22000, proficiency: 6 },
  { cr: 20, label: '20', xp: 25000, proficiency: 6 },
  { cr: 21, label: '21', xp: 33000, proficiency: 7 },
  { cr: 22, label: '22', xp: 41000, proficiency: 7 },
  { cr: 23, label: '23', xp: 50000, proficiency: 7 },
  { cr: 24, label: '24', xp: 62000, proficiency: 7 },
  { cr: 25, label: '25', xp: 75000, proficiency: 8 },
  { cr: 26, label: '26', xp: 90000, proficiency: 8 },
  { cr: 30, label: '30', xp: 155000, proficiency: 9 },
]

export const getCrLabel = (cr: number | undefined) =>
  challengeRatingInfo.find((xp) => xp.cr === cr)?.label

export const attributes = [
  'strength',
  'dexterity',
  'constitution',
  'intelligence',
  'wisdom',
  'charisma',
] as const

export const skills = [
  'acrobatics',
  'animal handling',
  'arcana',
  'athletics',
  'deception',
  'history',
  'insight',
  'intimidation',
  'investigation',
  'medicine',
  'nature',
  'perception',
  'performance',
  'persuasion',
  'religion',
  'sleight of hand',
  'stealth',
  'survival',
]

export const skillsProperties = [
  'acrobatics',
  'animalHandling',
  'arcana',
  'athletics',
  'deception',
  'history',
  'insight',
  'intimidation',
  'investigation',
  'medicine',
  'nature',
  'perception',
  'performance',
  'persuasion',
  'religion',
  'sleightOfHand',
  'stealth',
  'survival',
]

export const skillOptions = [
  { label: 'acrobatics', value: 'acrobatics' },
  { label: 'animal handling', value: 'animalHandling' },
  { label: 'arcana', value: 'arcana' },
  { label: 'athletics', value: 'athletics' },
  { label: 'deception', value: 'deception' },
  { label: 'history', value: 'history' },
  { label: 'insight', value: 'insight' },
  { label: 'intimidation', value: 'intimidation' },
  { label: 'investigation', value: 'investigation' },
  { label: 'medicine', value: 'medicine' },
  { label: 'nature', value: 'nature' },
  { label: 'perception', value: 'perception' },
  { label: 'performance', value: 'performance' },
  { label: 'persuasion', value: 'persuasion' },
  { label: 'religion', value: 'religion' },
  { label: 'sleight of hand', value: 'sleightOfHand' },
  { label: 'stealth', value: 'stealth' },
  { label: 'survival', value: 'survival' },
] as const
